import React, { Component } from 'react';

class Share extends Component {
  render() {

    if(this.props.data){
      var share = this.props.data.share.map(function(share){
        return  <li key={share.user}>
            {/* <blockquote>
               <p>{share.text}</p>
               <cite>{share.user}</cite>
            </blockquote> */}
         </li>
      })
    }

    return (
      <section id="share">
      <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
            </div>

            <div className="ten columns flex-container">
                  <ul className="slides">
                     {share}
                  </ul>
               </div>
            </div>
         </div>
   </section>
    );
  }
}

export default Share;
